//银行缩写
let bankList = {
  工商银行: 'ICBC',
  农业银行: 'ABC',
  中国银行: 'BOC',
  建设银行: 'CCB',
  交通银行: 'BCOM',
  邮储银行: 'CPSRB',
  招商银行: 'CMB',
  中信银行: 'CITIC',
  光大银行: 'CEB',
  广发银行: 'CGB',
  广东发展银行: 'GDB',
  浦发银行: 'SPDB',
  宁波银行: 'NBBK',
  北京银行: 'BOB',
  渤海银行: 'BHBK',
  北京农商行: 'BRCB',
  民生银行: 'CMBC',
  兴业银行: 'CIB',
  平安银行: 'PINGAN',
  华夏银行: 'HXB',
  广州农村商业银行: 'GRCB',
  广州银行: 'GZCB',
  徽商银行: 'AH-HSBK',
  江苏银行: 'JSBK',
  河北省农村信用社联合社: 'HEBNXS',
  江苏泗洪农村商业银行: 'JSSHRCB',
};
//银行缩写-英文缩写、中文
let bankListInversion = {
  ICBC: '工商银行',
  ABC: '农业银行',
  BOC: '中国银行',
  CCB: '建设银行',
  BCOM: '交通银行',
  CPSRB: '邮储银行',
  CMB: '招商银行',
  CITIC: '中信银行',
  CEB: '光大银行',
  CGB: '广发银行',
  GDB: '广东发展银行',
  SPDB: '浦发银行',
  NBBK: '宁波银行',
  BOB: '北京银行',
  BHBK: '渤海银行',
  BRCB: '北京农商行',
  CMBC: '民生银行',
  CIB: '兴业银行',
  PINGAN: '平安银行',
  HXB: '华夏银行',
  GRCB: '广州农村商业银行',
  GZCB: '广州银行',
  'AH-HSBK': '徽商银行',
  JSBK: '江苏银行',
  HEBNXS: '河北省农村信用社联合社',
  JSSHRCB: '江苏泗洪农村商业银行',
};
//数字银行
let bankListNum = {
  102: '工商银行',
  103: '农业银行',
  104: '中国银行',
  105: '建设银行',
  301: '交通银行',
  403: '邮储银行',
  308: '招商银行',
  302: '中信银行',
  303: '光大银行',
  306: '广东发展银行',
  310: '浦发银行',
  7002: '宁波银行',
  7007: '北京银行',
  318: '渤海银行',
  7008: '北京农商行',
  305: '民生银行',
  309: '兴业银行',
  783: '平安银行',
  304: '华夏银行',
  7046: '广州农村商业银行',
  7048: '广州银行',
  7006: '上海银行',
  9013: '微信支付',
  319: '徽商银行',
};
//银行数字v、l
let BANK_MSG = [
  { v: '102', l: '工商银行' },
  { v: '103', l: '农业银行' },
  { v: '104', l: '中国银行' },
  { v: '105', l: '建设银行' },
  { v: '301', l: '交通银行' },
  { v: '403', l: '邮储银行' },
  { v: '308', l: '招商银行' },
  { v: '302', l: '中信银行' },
  { v: '303', l: '光大银行' },
  { v: '306', l: '广东发展银行' },
  { v: '310', l: '浦发银行' },
  { v: '7002', l: '宁波银行' },
  { v: '7007', l: '北京银行' },
  { v: '318', l: '渤海银行' },
  { v: '7008', l: '北京农商行' },
  { v: '305', l: '民生银行' },
  { v: '309', l: '兴业银行' },
  { v: '783', l: '平安银行' },
  { v: '304', l: '华夏银行' },
  { v: '7046', l: '广州农村商业银行' },
  { v: '7048', l: '广州银行' },
  { v: '7006', l: '上海银行' },
  { v: '9013', l: '微信支付' },
  { v: '319', l: '徽商银行' },
];
//银行缩写v、l
let BANKLIST = [
  { v: '请选择', l: '' },
  { v: '工商银行', l: 'ICBC' },
  { v: '农业银行', l: 'ABC' },
  { v: '中国银行', l: 'BOC' },
  { v: '建设银行', l: 'CCB' },
  { v: '交通银行', l: 'BCOM' },
  { v: '邮储银行', l: 'CPSRB' },
  { v: '招商银行', l: 'CMB' },
  { v: '中信银行', l: 'CITIC' },
  { v: '光大银行', l: 'CEB' },
  { v: '广发银行', l: 'CGB' },
  { v: '广东发展银行', l: 'GDB' },
  { v: '浦发银行', l: 'SPDB' },
  { v: '宁波银行', l: 'NBBK' },
  { v: '北京银行', l: 'BOB' },
  { v: '渤海银行', l: 'BHBK' },
  { v: '北京农商行', l: 'BRCB' },
  { v: '民生银行', l: 'CMBC' },
  { v: '兴业银行', l: 'CIB' },
  { v: '平安银行', l: 'PINGAN' },
  { v: '华夏银行', l: 'HXB' },
  { v: '广州农村商业银行', l: 'GRCB' },
  { v: '广州银行', l: 'GZCB' },
  { v: '徽商银行', l: 'AH-HSBK' },
  { v: '江苏银行', l: 'JSBK' },
  { v: '河北省农村信用社联合社', l: 'HEBNXS' },
  { v: '江苏泗洪农村商业银行', l: 'JSSHRCB:' },
];
//spk 阿里与微保 code 集合  阿里=>微保
let bankCodeList = {
  ICBC: 'ICBC',
  ABC: 'ABC',
  BOC: 'BOC',
  CCB: 'CCB',
  COMM: 'BCOM',
  PSBC: 'CPSRB',
  CIB: 'CIB',
  CITIC: 'CITIC',
  CEB: 'CEB',
  CGB: 'CGB',
  GDB: 'GDB',
  SPDB: 'SPDB',
  NBBANK: 'NBBK',
  BJBANK: 'BOB',
  BOHAIB: 'BHBK',
  BJRCB: 'BRCB',
  SPABANK: 'PINGAN',
  CMB: 'CMB',
  SHBANK: 'SHBK',
  CMBC: 'CMBC',
  GRCB: 'GRCB',
  GCB: 'GZCB',
  HSBANK: 'AH-HSBK',
};
//银行缩写数字
let bankNameList = [
  { n: '工商银行', c: 'ICBC', numC: '102' },
  { n: '农业银行', c: 'ABC', numC: '103' },
  { n: '中国银行', c: 'BOC', numC: '104' },
  { n: '建设银行', c: 'CCB', numC: '105' },
  { n: '交通银行', c: 'BCOM', numC: '301' },
  { n: '邮储银行', c: 'CPSRB', numC: '403' },
  { n: '兴业银行', c: 'CIB', numC: '309' },
  { n: '中信银行', c: 'CITIC', numC: '302' },
  { n: '光大银行', c: 'CEB', numC: '303' },
  { n: '广东发展银行', c: 'GDB', numC: '306' },
  { n: '浦发银行', c: 'SPDB', numC: '310' },
  { n: '宁波银行', c: 'NBBK', numC: '7002' },
  { n: '北京银行', c: 'BOB', numC: '7007' },
  { n: '渤海银行', c: 'BHBK', numC: '318' },
  { n: '北京农商行', c: 'BRCB', numC: '7008' },
  { n: '平安银行', c: 'PINGAN', numC: '783' },
  { n: '招商银行', c: 'CMB', numC: '308' },
  { n: '民生银行', c: 'CMBC', numC: '305' },
  { n: '广州农村商业银行', c: 'GRCB', numC: '7046' },
  { n: '广州银行', c: 'GZCB', numC: '7048' },
  { n: '徽商银行', c: 'AH-HSBK', numC: '319' },
];
let bankListNC = [
  { n: '工商银行', c: '102' },
  { n: '农业银行', c: '103' },
  { n: '中国银行', c: '104' },
  { n: '建设银行', c: '105' },
  { n: '交通银行', c: '301' },
  { n: '邮储银行', c: '403' },
  { n: '兴业银行', c: '309' },
  { n: '中信银行', c: '302' },
  { n: '光大银行', c: '303' },
  { n: '广东发展银行', c: '306' },
  { n: '浦发银行', c: '310' },
  { n: '宁波银行', c: '7002' },
  { n: '北京银行', c: '7007' },
  { n: '渤海银行', c: '318' },
  { n: '北京农商行', c: '7008' },
  { n: '平安银行', c: '783' },
  { n: '招商银行', c: '308' },
  { n: '上海银行', c: '7006' },
  { n: '民生银行', c: '305' },
  { n: '华夏银行', c: '304' },
];
let bankCode = {
  工商银行: 'ICBC',
  农业银行: 'ABC',
  中国银行: 'BOC',
  建设银行: 'CCB',
  交通银行: 'BCOM',
  邮储银行: 'CPSRB',
  招商银行: 'CMB',
  中信银行: 'CITIC',
  光大银行: 'CEB',
  广发银行: 'CGB',
  广东发展银行: 'GDB',
  浦发银行: 'SPDB',
  宁波银行: 'NBBK',
  北京银行: 'BOB',
  渤海银行: 'BHBK',
  北京农商行: 'BRCB',
  民生银行: 'CMBC',
  兴业银行: 'CIB',
  平安银行: 'PINGAN',
  华夏银行: 'HXB',
  广州农村商业银行: 'GRCB',
  广州银行: 'GZCB',
  徽商银行: 'AH-HSBK',
  江苏银行: 'JSBK',
  河北省农村信用社联合社: 'HEBNXS',
  江苏泗洪农村商业银行: 'JSSHRCB',
};
//人脸
let ERROR_CODES = {
  0: '人脸验证成功',
  400101: '不合法的请求',
  400102: '不合法的请求',
  400103: '服务器拒绝访问此接口',
  400104: '服务器拒绝访问此接口',
  400502: '请求上送版本参数错误',
  400504: '请求访问频率过高',
  400601: '请求参数提过大',
  66660001: '不合法请求',
  66660002: '服务已过有效期',
  66660004: '无法确认为同一人',
  66660005: '无照片信息，无法比对',
  66660006: '1.请确保光线充足;2.请确保人脸正对框内;3.请确保脸部无遮挡',
  66660008: '服务处理失败,请稍后再试',
  66660009: '服务处理失败,请稍后再试',
  66660010: '姓名和身份证不一致，请确认',
  66660011: '无人脸验证结果',
  66660012: '服务处理失败,请稍后再试',
  66660014: '活体检测未通过',
  66660015: '姓名或身份证不合法',
  66660016: '不合法请求',
  66660017: '验证次数超限',
  66660018: '无此ID的用户身份信息',
  66660019: '比对源照片问题,无法解码',
  66660020: '身份证识别无结果，确认订单号是否正确',
  66660022: '唇语失效,请重新拉取',
  66660023: '请确保正脸对框且光线充足',
  66660024: '无此证件号码',
  66660025: '请确保本人操作且正脸对框',
  66660030: '不支持此类型服务',
  66660035: '未识别到人脸，请确保正脸对框且清晰完整',
  66660037: '照片出现多张脸',
  66660038: '未识别到人脸，请确保正脸对框且清晰完整',
  66660039: '比对源照片问题，无法解析',
  66660040: '比对源照片问题，无法解析',
  66661001: '识别错误，非身份证件或图像质量问题',
  66661003: '身份证已失效',
  66661004: '非法日期',
  66661005: '图像解码异常',
  66661006: '非身份证人像面',
  66661007: '非身份证国徽面',
  66661008: '姓名或身份证号未能识别',
  66661010: '请保持证件与边框对齐',
  66661011: '身份证有误，请重拍',
  66661012: '签发机关未能识别',
  '-1101': '未识别到人脸，请确保正脸对框且清晰完整',
  '-1102': '未识别到人脸，请确保正脸对框且清晰完整',
  '-1404': '请确保本人操作且正脸对框',
  '-4006': '视频中自拍照特征提取或检测失败',
  '-4007': '视频中自拍照特征提取或检测失败',
  '-4009': '比对源照片问题,无法解码',
  '-4010': '视频中自拍照特征提取或检测失败',
  '-4015': '视频中自拍照特征提取或检测失败',
  '-4017': '比对源照片问题，无法解析',
  '-4018': '比对源照片问题，无法解析',
  '-5001': '视频中自拍照特征提取或检测失败',
  '-5002': '视频中自拍照特征提取或检测失败',
  '-5005': '视频中自拍照特征提取或检测失败',
  '-5007': '视频没有声音或声音太小',
  '-5008': '声音未能识别，请大声慢读一遍数字',
  '-5009': '视频中自拍照特征提取或检测失败',
  '-5010': '声音未能识别；请大声慢读一遍数字',
  '-5011': '未识别到人脸；请保持本人操作且正脸对框',
  '-5012': '视频中噪声太大',
  '-5016': '请确保正脸对框且光线充足',
  '-5014': '活体检测失败',
  '-5015': '视频像素太低,最小270*480',
  '-5018': '未识别到眨眼；录制时请保持正脸对框且眨眼',
};
//关系证明
let relationWithPH = {
  '00': '本人',
  '01': '父子',
  '02': '父女',
  '03': '母子',
  '04': '母女',
  '05': '祖孙',
  '06': '配偶',
  '07': '兄弟',
  '08': '兄妹',
  '09': '姐弟',
  10: '姐妹',
  11: '叔侄',
  12: '姑侄',
  13: '外甥',
  14: '媳',
  15: '婿',
  16: '姐夫',
  17: '朋友',
  18: '同事',
  19: '师生',
  20: '雇佣',
  21: '其他',
  22: '父母',
  23: '子女',
  24: '其他法定监护人',
};

//更换投保人关系证明
let replacePolicyRelationWithPH = {
  '00': '本人',
  '05': '祖孙',
  '06': '配偶',
  '07': '兄弟',
  '08': '兄妹',
  '09': '姐弟',
  10: '姐妹',
  11: '叔侄',
  12: '姑侄',
  17: '朋友',
  18: '同事',
  19: '师生',
  20: '雇佣',
  21: '其他',
  22: '父母',
  23: '子女',
  24: '其他法定监护人',
};

//纳税类型
let statusList = [
  {
    value: 'Y',
    desc: '仅为中国税收居民',
  },
  {
    value: 'N',
    desc: '仅为非居民',
  },
  // {
  //     value: '2',
  //     desc: '既是中国税收居民又是其他税收管辖区居民',
  // }
];
//证件类型
let IDTYPE = {
  0: '身份证',
  1: '护照',
  2: '军官证',
  3: '驾照',
  4: '户口本',
  5: '学生证',
  6: '工作证',
  7: '出生证',
  8: '其他',
  9: '无证件',
  10: '港澳台居民居住证',
  11: '外国人永久居留身份证',
  A: '士兵证',
  B: '港澳居民来往内地通行证',
  C: '临时身份证',
  D: '警官证',
  E: '台湾居民来往大陆通行证',
};
//证件类型-身份证，户口本，外国人永久居留身份证
const IDTYPE11 = {
  0: '身份证',
  4: '户口本',
  11: '外国人永久居留身份证',
};
//出险人证件类型-身份证，外国人永久居留身份证
const IDTYPENEW = {
  0: '身份证',
  11: '外国人永久居留身份证',
};
//证件类型-反
let IDTYPE_RE = {
  身份证: 0,
  护照: 1,
  军官证: 2,
  驾照: 3,
  户口本: 4,
  学生证: 5,
  工作证: 6,
  出生证: 7,
  其他: 8,
  无证件: 9,
  港澳台居民居住证: 10,
  外国人永久居留身份证: 11,
};
//证件类型 只有身份证户口本
let IDTYPE0 = {
  0: '身份证',
  4: '户口本',
};
//证件类型-港澳台
let cardTypePH = {
  0: '身份证',
  1: '护照',
  2: '军人证（军官证）',
  3: '驾照',
  4: '户口本',
  5: '学生证',
  6: '工作证',
  7: '出生证',
  8: '其它',
  9: '无证件',
  10: '港澳台居民居住证',
  11: '外国人永久居留身份证',
  A: '士兵证',
  B: '港澳居民来往内地通行证',
  C: '临时身份证',
  D: '警官证',
  E: '台湾居民来往大陆通行证',
};
//证件类型code,name
let ID_TYPE = [
  { code: '0', name: '身份证' },
  { code: '1', name: '护照' },
  { code: '2', name: '军官证' },
  { code: '4', name: '户口本' },
  { code: 'A', name: '士兵证' },
  { code: 'B', name: '港澳居民来往内地通行证' },
  { code: 'C', name: '临时身份证' },
  { code: 'E', name: '台湾居民来往大陆通行证' },
  { code: '10', name: '港澳台居民居住证' },
  { code: '11', name: '外国人永久居留身份证' },
];
//证件类型code,name，身份证-户口本-外国人永久居留身份证
let ID_TYPENEW = [
  { code: '0', name: '身份证' },
  { code: '4', name: '户口本' },
  { code: '11', name: '外国人永久居留身份证' },
];
let sex = {
  MAN: '男',
  WOMEN: '女',
  // UNKNOWN: "不详",
};
let SEX = {
  MAN: '0',
  WOMEN: '1',
  // UNKNOWN: "2",
};
let sexNew = {
  0: '男',
  1: '女',
};
let SEXNEW = [
  [
    { label: '男', value: 0 },
    { label: '女', value: 1 },
  ],
];
let sexNum = {
  0: 'M',
  1: 'F',
};
//出险原因
let ACCIDENT_CAUSE = [
  { v: '1', l: '意外' },
  { v: '2', l: '疾病' },
];
//资料递交方式
let DELIVERY_METHOD = [
  { v: '01', l: '本人递送' },
  { v: '02', l: '快递至公司' },
  { v: '03', l: '上门收取' },
];
//理赔类型
let CLAIMTYPE = {
  1: [
    { v: '102', l: '意外身故' },
    { v: '104', l: '重大疾病' },
    { v: '103', l: '意外高残' },
    { v: '105', l: '特定疾病' },
    { v: '109', l: '豁免' },
    { v: '101', l: '意外伤残' },
    { v: '100', l: '意外医疗' },
    { v: '10A', l: '意外津贴' },
  ],
  2: [
    { v: '202', l: '疾病身故' },
    { v: '204', l: '重大疾病' },
    { v: '203', l: '疾病高残' },
    { v: '205', l: '特定疾病' },
    { v: '209', l: '豁免' },
    { v: '201', l: '疾病伤残' },
    { v: '200', l: '疾病医疗' },
    { v: '20A', l: '疾病津贴' },
  ],
};
//办理方式
let EDOR_APP_TYPE = [
  { v: '1', l: '柜面亲办' },
  { v: '2', l: '业务员代办' },
  { v: '3', l: '其他人代办' },
  { v: '4', l: '信函申请' },
  { v: '5', l: '电话申请' },
  { v: '6', l: '内部转办' },
  { v: '7', l: '官网申请' },
  { v: '8', l: '微信申请' },
  { v: '9', l: '银行柜面申请' },
  { v: '13', l: '第三方平台' },
  { v: '14', l: '视频申请' },
];
//问卷
let questionList = {
  '01': '健康问卷-一般疾病',
  '02': '健康问卷-乳腺疾病',
  '03': '健康问卷-呼吸系统疾病',
  '04': '健康问卷-哮喘',
  '05': '健康问卷-失明',
  '06': '健康问卷-失聪',
  '07': '健康问卷-女性健康',
  '08': '健康问卷-婴幼儿健康状况补充问卷',
  '09': '健康问卷-心电图异常',
  10: '健康问卷-意外',
  11: '健康问卷-既往疾病',
  12: '健康问卷-泌尿系统疾病',
  13: '健康问卷-消化系统疾病',
  14: '健康问卷-甲状腺疾病',
  15: '健康问卷-痛风',
  16: '健康问卷-癫痫',
  17: '健康问卷-类风湿关节炎',
  18: '健康问卷-精神',
  19: '健康问卷-肝脏疾病',
  20: '健康问卷-肿瘤',
  21: '健康问卷-胸痛',
  22: '健康问卷-腹痛问卷',
  23: '健康问卷-血压异常',
  24: '健康问卷-血尿',
  25: '健康问卷-血糖异常',
  26: '健康问卷-贫血',
  27: '健康问卷-饮酒',
  28: '健康问卷-骨骼与肌肉系统问卷',
  29: '出国人员问卷',
  30: '北京人寿保险股份有限公司财务问卷',
  31: '北京人寿保险股份有限公司财务问卷—京福传家、京福世家',
  32: '外籍人士问卷',
  33: '职业问卷',
  34: '高风险职业问卷',
  35: '港澳台人士问卷',
};
export default {
  bankList,
  bankListInversion,
  bankListNum,
  BANK_MSG,
  BANKLIST,
  bankCodeList,
  bankNameList,
  bankListNC,
  bankCode,
  ERROR_CODES,
  relationWithPH,
  replacePolicyRelationWithPH,
  statusList,
  IDTYPE,
  IDTYPE11,
  IDTYPENEW,
  IDTYPE_RE,
  IDTYPE0,
  ID_TYPE,
  ID_TYPENEW,
  cardTypePH,
  sex,
  SEX,
  sexNew,
  sexNum,
  SEXNEW,
  ACCIDENT_CAUSE,
  DELIVERY_METHOD,
  CLAIMTYPE,
  EDOR_APP_TYPE,
  questionList,
};
